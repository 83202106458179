import { faTimesSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Modal = () => {
  const [showModal, setShowModal] = useState(true);
  return (
    <>
      {showModal ? (
        <>
          <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                <div className="relative transform overflow-hidden rounded text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="">
                    <div className="flex absolute justify-end w-full float-right">
                      <FontAwesomeIcon icon={faTimesSquare} className="mr-1 my-0" onClick={() => setShowModal(false)} style={{ 'cursor': 'pointer', 'fontSize': '20pt' }} />
                    </div>
                    <div className="sm:flex sm:items-start">
                      <img src="./img/news/prevencion-llamadas.jpg" alt="Formulario" />
                      {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLScxsmVsF7pwWt9a12pEvyFGwmnS-QgwKXZQ7Eg2AZLNY7OM1g/viewform?usp=sf_link" target="_blank" rel="noreferrer"
                        style={{
                          "background": "transparent", "position": "absolute", "bottom": "0", "height": "55pt", "width": "60%",
                          "cursor": "pointer", "marginLeft": "auto", "marginRight": "auto", "left": "0", "right": "0"
                        }}>
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Modal;