import React from "react";
import PropTypes from "prop-types";

const CardValores = ({ title, content, color, ruta, width }) => {
  return (
    <div className={`w-96 h-40 rounded-md px-4 m-auto py-2 text-secondary bg-${color} border flex flex-row shadow`}>
      <div className="grid justify-center items-center m-5">
        <img
          src={ruta}
          width={250}
          alt={title}
          className={`w-${width} h-auto`}
        />
      </div>
      <div className="col">
        <h4 className="text-left mb-2 font-bold text-xl">{title}</h4>
        <p className="text-justify text-sm">{content}</p>
      </div>
    </div>
  );
};
CardValores.prototype = {
  cotitlelor: PropTypes.string,
  content: PropTypes.string,
  color: PropTypes.string,
  ruta: PropTypes.string,
  width: PropTypes.string
};

export default CardValores;
