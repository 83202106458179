import { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { faChildren, faUserTie, faFileWord, faFileSignature, faArrowUpRightFromSquare, faTimesSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "../components/Tooltip";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal)

const initialState = {
    showFormulario: false,
    showAceptados: false,
    loading: false,
    // campos nino
    photo_child: '',
    identificacion_child: '',
    fullName_child: '',
    fechaNacimiento_child: '',
    tipoSangre_child: 'O+',
    genero_child: 'Masculino',
    domicilio_child: '',
    antecedentesClinicos_child: '',
    conadis_child: 'NO',
    conadisFile_child: '',
    // campos parent
    identificacion_parent: '',
    fullName_parent: '',
    email_parent: '',
    cellphone_parent: '',
    ocupacion_parent: '',
    domicilio_parent: '',
    // docs
    copia_cedula: '',
    oficio: '',
    carta_asignacion: '',
    acta_exoneracion: '',
    //terminos
    check_terminos: false,
    promocion: 6
}


const BomberosJr = () => {
    const [state, setState] = useState(initialState);

    const [bomberosJrAceptados, setBomberosJrAceptados] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState('');

    const readAceptados = async () => {
        setState({ ...state, loading: true });
        const url = "https://bp.bomberosmanta.gob.ec/api/bomberosjrs?aceptado=true&fields=fullName_child,fullName_parent";
        // const url = "http://127.0.0.1:8001/api/bomberosjrs?aceptado=true&fields=fullName_child,fullName_parent";
        const rawResponse = await fetch(url, {
            method: 'GET',
            headers: {
                'X-requested-With': 'XMLHttpRequest',
            }
        });
        if (rawResponse.ok) {
            const content = await rawResponse.json();
            setState({ ...state, loading: false });
            setBomberosJrAceptados(content);
        } else {
            setBomberosJrAceptados([]);
            setState({ ...state, loading: false });
        }
    }

    useEffect(() => {
        if (initialState.showAceptados) {
            readAceptados()
        }
    }, [])

    const guardar = async () => {
        setState({ ...state, loading: true });

        /** validate  */
        for (const key in state) {
            if (Object.hasOwnProperty.call(state, key)) {
                const element = state[key];
                const nombre = document.getElementsByName(key)[0];
                if (nombre !== undefined) {
                    const nombrecampo = nombre.getAttribute("nombrecampo");
                    if (key !== 'conadisFile_child') {
                        if (element === undefined || element.toString().trim() === "") {
                            const mensaje = `El campo ${nombrecampo} es obligatorio.`;
                            setState({ ...state, loading: false })
                            MySwal.fire({
                                title: <strong>Alerta!</strong>,
                                html: <i>{mensaje}</i>,
                                icon: 'warning',
                                timer: 3000,
                                toast: true,
                                position: 'bottom-end',
                                timerProgressBar: true,
                                showConfirmButton: false
                            });
                            return;
                        }
                    }
                }
            }
        }
        /** validate  */

        // const url = "http://127.0.0.1:8000/api/bomberosjr";
        const url = "https://bp.bomberosmanta.gob.ec/api/bomberosjr";
        const form = document.querySelector('form');
        const rawResponse = await fetch(url, {
            method: 'POST',
            headers: {
                'X-requested-With': 'XMLHttpRequest'
            },
            body: new FormData(form)
        });
        // .catch(e => {
        //     setState({ ...state, loading: false });
        //     console.log(e);
        //     // return e;
        // });

        if (rawResponse.ok) {
            const content = await rawResponse.json();

            // MySwal.fire({
            //     title: <strong>Alerta!</strong>,
            //     text: content.message,
            //     icon: 'success'
            // });

            setContentModal('data:application/pdf;base64,' + content.data);

            setShowModal(true)

            setState({ ...state, loading: false });

        } else {
            const content = await rawResponse.json();
            setTimeout(() => {
                setState({ ...state, loading: false })
            }, 1200);

            if (content.status !== 500) {
                MySwal.fire({
                    title: <strong>Alerta!</strong>,
                    text: content.message,
                    icon: 'warning',
                });
            } else {
                MySwal.fire({
                    title: <strong>Alerta!</strong>,
                    text: "Hubo un inconveniente, vuelva a reintentar.",
                    icon: 'warning',
                });
            }

        }
    }

    const showTable = () => {
        const tableRows = [];
        for (let i = 0; i < bomberosJrAceptados.length; i = i + 3) {
            tableRows.push(
                <tr key={bomberosJrAceptados[i] + "-" + bomberosJrAceptados[i + 1] + "-" + bomberosJrAceptados[i + 2] + "-" + i}>
                    <td>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                            <div style={{ textAlign: 'left' }}>
                                <span style={{ marginRight: '2pt' }}>{i + 1}.-</span>
                                {bomberosJrAceptados[i]['fullName_child']}
                            </div>
                            <span style={{ fontSize: '9pt', 'color': '#808080', marginLeft: '25pt' }}>
                                {bomberosJrAceptados[i]['fullName_parent']}
                            </span>
                        </div>
                    </td>

                    {
                        bomberosJrAceptados[i + 1] !== undefined ?
                            (
                                <td>
                                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                        <div style={{ textAlign: 'left' }}>
                                            <span style={{ marginRight: '2pt' }}>{i + 2}.-</span>
                                            {bomberosJrAceptados[i + 1]['fullName_child']}
                                        </div>
                                        <span style={{ fontSize: '9pt', 'color': '#808080', marginLeft: '25pt' }}>
                                            {bomberosJrAceptados[i + 1]['fullName_parent']}
                                        </span>
                                    </div>
                                </td>
                            )
                            : ''
                    }

                    {
                        bomberosJrAceptados[i + 2] !== undefined ?
                            (
                                <td>
                                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                        <div style={{ textAlign: 'left' }}>
                                            <span style={{ marginRight: '2pt' }}>{i + 3}.-</span>
                                            {bomberosJrAceptados[i + 2]['fullName_child']}
                                        </div>
                                        <span style={{ fontSize: '9pt', 'color': '#8c8c8c', marginLeft: '25pt' }}>
                                            {bomberosJrAceptados[i + 2]['fullName_parent']}
                                        </span>
                                    </div>
                                </td>
                            )
                            : ''
                    }
                </tr>
            );
        }
        return tableRows;
    }

    const closeModal = () => {
        const form = document.querySelector('form');
        form.reset();
        setState(initialState)
        setShowModal(false);
    }

    const ModalPdf = () => {
        return (
            <>
                {showModal ? (
                    <>
                        <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                            <div className="fixed inset-0 z-10 overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                                    <div className="relative transform overflow-hidden rounded text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div className="flex flex-col">
                                            <div className="flex justify-end w-full float-right">
                                                <FontAwesomeIcon icon={faTimesSquare} className="m-0 text-white" onClick={ closeModal } style={{ 'cursor': 'pointer', 'fontSize': '20pt' }} />
                                            </div>
                                            <div className="sm:flex sm:items-start">
                                                <iframe src={contentModal} title="Modal Comprobante" width="100%" height="600px"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
            </>
        );
    };

    return (
        initialState.showFormulario ?
            <Layout>
                <h1 className="text-center text-red-700 font-bold text-xl mt-4">
                    Registro de Bomberos Jr.
                </h1>
                {/* <div className="bg-red-700 text-white py-2 px-4 mr-8 ml-8 font-bold border border-2 border-black rounded-md mt-4">
                    El curso es gratuito. Sin embargo se deberá ajustarse a los siguientes
                    <strong className="ml-1">
                        <a href="img/bomberosjr/bomberosjr.jpeg" target="_blank" rel="noopener noreferrer">
                            TÉRMINOS DE REFERENCIA
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-1" />
                        </a>
                    </strong>
                </div> */}
                <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" id="formularioDatos" encType="multipart/form-data" name="formularioDatos" method="POST">
                    <div className="flex px-8 flex-wrap gap-x-8 gap-y-4">
                        <div className="flex-col flex-1">
                            <div className="shadow-md border rounded p-2">
                                <div className="px-3 my-1">
                                    <strong>
                                        <p className="text-gray-600">
                                            <FontAwesomeIcon icon={faChildren} className="mr-2" />Datos del niño(a)
                                        </p>
                                    </strong>
                                    <hr className="mt-2 mb-3" />
                                </div>
                                <div className="px-2 flex-col">
                                    <input type="hidden" name="promocion" id="promocion" value={state.promocion} />
                                    <div className="mb-4 flex items-center">
                                        <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="photo_child">Foto carnet</label>
                                        <input className="uppercase shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="photo_child" type="file" value={state.photo_child} name="photo_child" accept="image/*"
                                            onChange={(event) => setState({ ...state, photo_child: event.target.value })} nombrecampo="Foto carnet" />
                                    </div>
                                    <div className="mb-4 flex items-center">
                                        <label className="w-60 text-gray-700 text-sm font-bold mr-2" htmlFor="identificacion_child">Cédula de Identidad</label>
                                        <input className="uppercase shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="identificacion_child" type="text" placeholder="Cédula de Identidad" value={state.identificacion_child} nombrecampo="Cédula de identidad"
                                            onChange={(event) => setState({ ...state, identificacion_child: event.target.value })} name="identificacion_child" maxLength={13} onKeyDown={function (e) {
                                                if (e.keyCode === '189') {
                                                    e.preventDefault()
                                                }
                                            }} />
                                    </div>
                                    <div className="mb-4 flex items-center">
                                        <label className="w-60 text-gray-700 text-sm font-bold mr-2" htmlFor="fullName_child">Nombres Completos</label>
                                        <input className="uppercase shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="fullName_child" type="text" placeholder="Nombres Completos" value={state.fullName_child} nombrecampo="Nombres Completos"
                                            onChange={(event) => setState({ ...state, fullName_child: event.target.value })} name="fullName_child" />
                                    </div>
                                    <div className="mb-4 flex items-center">
                                        <label className="w-60 text-gray-700 text-sm font-bold mr-2" htmlFor="fechaNacimiento_child">Fecha Nacimiento</label>
                                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="fechaNacimiento_child" type="date" value={state.fechaNacimiento_child} nombrecampo="Fecha de Nacimiento"
                                            onChange={(event) => setState({ ...state, fechaNacimiento_child: event.target.value })} name="fechaNacimiento_child" />
                                    </div>
                                    <div className="mb-4 flex items-center">
                                        <label className="w-60 text-gray-700 text-sm font-bold mr-2" htmlFor="GrupoSanguineo">
                                            Grupo Sanguíneo
                                        </label>
                                        <select value={state.tipoSangre_child} onChange={(event) => setState({ ...state, tipoSangre_child: event.target.value })}
                                            className="form-select shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            aria-label="Default select example" name="tipoSangre_child" nombrecampo="Grupo Sanguíneo">
                                            <option value="A+">A+</option>
                                            <option value="A-">A-</option>
                                            <option value="B+">B+</option>
                                            <option value="B-">B-</option>
                                            <option value="O+">O+</option>
                                            <option value="O-">O-</option>
                                            <option value="AB+">AB+</option>
                                            <option value="AB-">AB-</option>
                                        </select>
                                    </div>
                                    <div className="mb-4 flex items-center">
                                        <label className="w-40 mr-2 text-gray-700 text-sm font-bold ">
                                            Género
                                        </label>
                                        <select value={state.genero_child} id="genero_child" onChange={(event) => setState({ ...state, genero_child: event.target.value })}
                                            className="form-select shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            aria-label="Default select example" name="genero_child" nombrecampo="Género">
                                            <option value="Masculino">Masculino</option>
                                            <option value="Femenino">Femenino</option>
                                            <option value="Otro">Otro</option>
                                        </select>
                                    </div>
                                    <div className="mb-4 flex items-center">
                                        <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="domicilio_child">Domicilio</label>
                                        <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="domicilio_child" type="text" placeholder="Direccion Domiciliaria" value={state.domicilio_child} nombrecampo="Domicilio"
                                            onChange={(event) => setState({ ...state, domicilio_child: event.target.value })} name="domicilio_child"></textarea>
                                    </div>
                                    <div className="mb-4 flex items-center">
                                        <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="antecedentesClinicos_child">Antecedentes Clínicos</label>
                                        <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="antecedentesClinicos_child" type="text" placeholder="Antecedentes Clinicos" value={state.antecedentesClinicos_child} nombrecampo="Antecedentes Clínicos"
                                            onChange={(event) => setState({ ...state, antecedentesClinicos_child: event.target.value })} name="antecedentesClinicos_child"></textarea>
                                    </div>
                                    <div className="mb-4 flex items-center">
                                        <label className="w-75 text-gray-700 text-sm font-bold mr-2">
                                            ¿Cuenta con carnet CONADIS?
                                        </label>
                                        <div className="px-1 flex flex-row">
                                            <input type="radio" value="on" name="conadis_child" checked={state.conadis_child === 'SI'} className="mr-1" nombrecampo="¿Carnet CONADIS?"
                                                onChange={(event) => setState({ ...state, conadis_child: "SI" })} />
                                            <label htmlFor="si">SI</label>
                                        </div>
                                        <div className="px-1 flex flex-row">
                                            <input type="radio" value="off" name="conadis_child" checked={state.conadis_child === 'NO'} className="mr-1" nombrecampo="¿Carnet CONADIS?"
                                                onChange={(event) => setState({ ...state, conadis_child: "NO" })} />
                                            <label htmlFor="no">NO</label>
                                        </div>
                                    </div>

                                    {(state.conadis_child === 'SI') ?
                                        <div className="mb-4 flex items-center">
                                            <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="conadisFile_child">
                                                Carnet de CONADIS
                                            </label>
                                            <div className="w-full">
                                                <input
                                                    className="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="antecedentes" type="file" value={state.conadisFile_child} name="conadisFile_child" accept="application/pdf"
                                                    onChange={(event) => setState({ ...state, conadisFile_child: event.target.value })} nombrecampo="Carnet de CONADIS" />
                                            </div>
                                        </div>
                                        : ""
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex-col flex-1">
                            <div className="shadow-md border rounded p-2">
                                <div className="px-3 my-1">
                                    <strong>
                                        <p className="text-gray-600">
                                            <FontAwesomeIcon icon={faUserTie} className="mr-2" />Datos del representante
                                        </p>
                                    </strong>
                                    <hr className="mt-2 mb-3" />
                                </div>
                                <div className="px-2 flex-col">
                                    <div className="mb-4 flex items-center">
                                        <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="identificacion_parent">Cédula de Identidad</label>
                                        <input className="uppercase shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="identificacion_parent" type="text" placeholder="Cédula de Identidad" value={state.identificacion_parent} nombrecampo="Cédula de identidad"
                                            onChange={(event) => setState({ ...state, identificacion_parent: event.target.value })} name="identificacion_parent" onKeyDown={function (e) {
                                                if (e.keyCode === '189') {
                                                    e.preventDefault()
                                                }
                                            }} />
                                    </div>
                                    <div className="mb-4 flex items-center">
                                        <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="fullName_parent">Nombres Completos</label>
                                        <input className="uppercase shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="fullName_parent" type="text" placeholder="Nombres Completos" value={state.fullName_parent} nombrecampo="Nombres Completos"
                                            onChange={(event) => setState({ ...state, fullName_parent: event.target.value })} name="fullName_parent" />
                                    </div>
                                    <div className="mb-4 flex items-center">
                                        <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="email_parent">Correo electrónico</label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="email_parent" type="text" placeholder="Correo electrónico" value={state.email_parent} nombrecampo="Correo electrónico"
                                            onChange={(event) => setState({ ...state, email_parent: event.target.value })} name="email_parent" />
                                    </div>
                                    <div className="mb-4 flex items-center">
                                        <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="cellphone_parent">Teléfono</label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="cellphone_parent" type="text" placeholder="Teléfono" value={state.cellphone_parent} nombrecampo="Teléfono"
                                            onChange={(event) => setState({ ...state, cellphone_parent: event.target.value })} name="cellphone_parent" />
                                    </div>
                                    <div className="mb-4 flex items-center">
                                        <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="ocupacion_parent">Ocupación</label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="ocupacion_parent" type="text" placeholder="Trabajo, actividad, etc" value={state.cellphone} nombrecampo="Ocupación"
                                            onChange={(event) => setState({ ...state, ocupacion_parent: event.target.value })} name="ocupacion_parent" />
                                    </div>
                                    <div className="mb-4 flex items-center">
                                        <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="domicilio_parent">Domicilio</label>
                                        <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="domicilio_parent" type="text" placeholder="Direccion" value={state.domicilio_parent} nombrecampo="Domicilio Representante Legal"
                                            onChange={(event) => setState({ ...state, domicilio_parent: event.target.value })} name="domicilio_parent"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="shadow-md border rounded p-2 mt-5">
                                <div className="px-3 my-1">
                                    <strong>
                                        <p className="text-gray-600">
                                            <FontAwesomeIcon icon={faFileSignature} className="mr-2" />Documentos
                                        </p>
                                    </strong>
                                    <hr className="mt-2 mb-3" />
                                </div>
                                <div className="px-2 flex-col">
                                    <div className="mb-4 flex flex-col justify-start">
                                        <label className="text-gray-700 text-sm font-bold mr-2 mb-1" htmlFor="copia_cedula">
                                            Copias de Cedula
                                        </label>
                                        <div className="flex items-center">
                                            <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="copia_cedula" type="file" value={state.copia_cedula} name="copia_cedula" accept="application/pdf"
                                                onChange={(event) => setState({ ...state, copia_cedula: event.target.value })} nombrecampo="Copias de Cedula" />
                                        </div>
                                        <p className="font-bold" style={{ "fontSize": "8pt", "color": "red", "marginLeft": "6pt", "marginTop": "2pt" }}>Cedula del niño(a), Cedula del Representante Legal</p>
                                    </div>
                                </div>
                                <div className="px-2 flex-col">
                                    <div className="mb-4 flex flex-col justify-start">
                                        <label className="text-gray-700 text-sm font-bold mr-2 mb-1" htmlFor="oficio">
                                            Oficio
                                        </label>
                                        <div className="flex items-center">
                                            <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="oficio" type="file" value={state.oficio} name="oficio" accept="application/pdf"
                                                onChange={(event) => setState({ ...state, oficio: event.target.value })} nombrecampo="Oficio" />
                                            <Tooltip text="Descargar Formato Archivo">
                                                <a href="./docs/bomberosjr/solicitud_ingreso_bomberosjr_2024.docx" target="_blank" rel="noreferrer"
                                                    className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mx-1">
                                                    <FontAwesomeIcon icon={faFileWord} />
                                                </a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-2 flex-col">
                                    <div className="mb-4 flex flex-col justify-start">
                                        <label className="text-gray-700 text-sm font-bold mr-2 mb-1" htmlFor="carta_asignacion">
                                            Carta Asignación de Retiro
                                        </label>
                                        <div className="flex items-center">
                                            <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="carta_asignacion" type="file" value={state.carta_asignacion} name="carta_asignacion" accept="application/pdf"
                                                onChange={(event) => setState({ ...state, carta_asignacion: event.target.value })} nombrecampo="Carta Asignacion" />
                                            <Tooltip text="Descargar Formato Archivo">
                                                <a href="./docs/bomberosjr/acta_retiro_2024.docx" target="_blank" rel="noreferrer"
                                                    className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mx-1">
                                                    <FontAwesomeIcon icon={faFileWord} />
                                                </a>
                                            </Tooltip>
                                        </div>
                                        <p className="font-bold" style={{ "fontSize": "8pt", "color": "red", "marginLeft": "6pt", "marginTop": "2pt" }}>
                                            Debe adjuntar la copia de cédula de la persona que va a realizar el retiro.
                                        </p>
                                    </div>
                                </div>
                                <div className="px-2 flex-col">
                                    <div className="mb-4 flex flex-col justify-start">
                                        <label className="text-gray-700 text-sm font-bold mr-2 mb-1" htmlFor="acta_exoneracion">
                                            Acta de Exoneración de Responsabilidad
                                        </label>
                                        <div className="flex items-center">
                                            <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="acta_exoneracion" type="file" value={state.acta_exoneracion} name="acta_exoneracion" accept="application/pdf"
                                                onChange={(event) => setState({ ...state, acta_exoneracion: event.target.value })} nombrecampo="Acta de Exoneracion" />
                                            <Tooltip text="Descargar Formato Archivo">
                                                <a href="./docs/bomberosjr/acta_exoneracion_responsabilidad_2024.docx" target="_blank" rel="noreferrer"
                                                    className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mx-1">
                                                    <FontAwesomeIcon icon={faFileWord} />
                                                </a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span style={{ "fontSize": "10pt", "marginLeft": "6pt", "marginTop": "5pt" }}>
                                        <strong>Nota: </strong>Los documentos deben ser subidos en archivo PDF.
                                    </span>
                                </div>
                            </div>

                            <div className="flex items-center justify-between pb-10 mb-5 mt-4">
                                <div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" name="check_terminos" id="check_terminos" value={state.check_terminos}
                                                onChange={(event) => setState({ ...state, check_terminos: !state.check_terminos })} />
                                            <span className="ml-2">Acepto los
                                                <strong className="ml-1">
                                                    <a href="docs/bomberosjr/reglamento_2024.pdf" target="_blank" rel="noopener noreferrer">
                                                        TÉRMINOS DE REFERENCIA
                                                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-1" />
                                                    </a>
                                                </strong> </span>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    {state.loading === true ?
                                        <button className="bg-blue-300 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                            type="button" disabled="disable">
                                            <span>Guardando...!</span>
                                        </button>
                                        :
                                        <button className={"text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline " + (state.check_terminos ? 'bg-blue-600 hover:bg-blue-500' : 'bg-blue-300 hover:bg-blue-300')}
                                            type="button" onClick={guardar} disabled={(!state.check_terminos) ? "disabled" : ""}>
                                            <span>Registrar</span>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />


                    {/* <div className="px-8" style={{"background":"red"}}>
                        <div className="flex items-center justify-end pb-10 mb-5">
                            {state.loading === true ?
                                <button className="bg-blue-300 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="button" onClick={guardar} disabled="disable">
                                    <span>Guardando...!</span>
                                </button>
                                :
                                <button className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="button" onClick={guardar}>
                                    <span>Registrar</span>
                                </button>
                            }

                        </div>
                    </div> */}
                </form>
                <ModalPdf></ModalPdf>
            </Layout>
            :
            (
                initialState.showAceptados ?
                    <Layout>
                        <h1 className="text-center text-red-700 font-bold text-xl mt-4">
                            Nómina de aspirantes a Bomberos Jr. aprobados, a la Segunda Fase  de seleccion 2023
                        </h1>
                        <div className="text-gray-700 py-2 px-4 mr-8 ml-8 font-bold border border-2 border-sky-600 rounded-md mt-4 mb-10" style={{ marginBottom: '100px' }}>
                            <table className="mb-1" style={{ width: '100%', textTransform: 'uppercase' }}>
                                <tbody>
                                    {initialState.loading ? (
                                        <tr>
                                            <td colSpan={2}>Cargando...</td>
                                        </tr>
                                    ) : (
                                        showTable()
                                    )}
                                </tbody>
                            </table>
                            <br />
                        </div>
                        <br />
                    </Layout>
                    :
                    <Layout>
                        <h1 className="text-center text-red-700 font-bold text-xl mt-4">
                            Registro de Bomberos JR.
                        </h1>
                        <div className="bg-lime-700 text-white py-2 px-4 mr-8 ml-8 font-bold border border-2 border-black rounded-md mt-4">
                            El plazo para el registro de BOMBEROS JR. ya finalizó.<br />
                        </div>
                    </Layout>
            )
    )
}

export default BomberosJr;