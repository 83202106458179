import React from "react";
import Layout from "../layout/Layout";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Modal from "../components/Modal";

const Home = () => {
  return (
    <Layout>
      <Carousel autoPlay={true} interval={5000} infiniteLoop={true} children={false} showThumbs={false} >
        <div style={{ "height": "calc(100vh - 180px)" }}>
          <img src="./img/slider/slider1.jpg" alt="" style={{ "height": "100%", "objectFit": "cover" }} />
          {/* <p className="legend">Legend 1</p> */}
        </div>
        <div style={{ "height": "calc(100vh - 180px)" }}>
          <img src="./img/slider/slider2.jpg" alt="" style={{ "height": "100%","objectFit":"cover" }} />
        </div>
        <div style={{ "height": "calc(100vh - 180px)" }}>
          <img src="./img/slider/slider3.jpg" alt="" style={{ "height": "100%","objectFit":"cover" }} />
        </div>
      </Carousel>
      <Modal></Modal>
    </Layout>
  );
};

export default Home;
