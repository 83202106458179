import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { Link, useParams } from "react-router-dom";
import {
  faArrowCircleLeft,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./style.css";
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DetalleNoticia = () => {
  let { id } = useParams();
  const [titleNoticia, setTitleNoticia] = useState("Noticias");
  const [noticia, setNoticia] = useState({
    title: "",
    content: "",
    datePublish: "",
    media: [],
  });

  const readNoticia = async () => {
    const url = "https://bp.bomberosmanta.gob.ec/api/news/" + id;
    const rawResponse = await fetch(url, {
      method: "GET",
      headers: {
        "X-requested-With": "XMLHttpRequest",
      },
    });
    if (rawResponse.ok) {
      const content = await rawResponse.json();
      setNoticia(content);
    } else {
      setNoticia({});
    }
  };

  useEffect(() => {
    readNoticia();
  }, []);

  return (
    <Layout>
      <div className="h-[20rem]">
        {/* <div className="w-full h-full">
          <img
            className="h-full w-full"
            src="/img/noticias/cover.jpeg"
            alt=""
            style={{
              height: "100%",
              objectFit: "cover",
              objectPosition: "50% 30%",
            }}
          />
        </div>
        <div className="w-full flex justify-center relative">
          <div className="w-3/4 -top-10 bg-black opacity-85 absolute py-4 rounded-md text-center text-4xl text-white font-bold">
            {titleNoticia}
          </div>
        </div> */}
        <div className="mt-5 mb-10 px-8 pb-[90pt]">
          <Link to={`/noticias/`}>
            <button
              className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-1 pr-3 pl-2 rounded"
              type="button"
            >
              <span>
                <FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Volver
              </span>
            </button>
          </Link>

          <div className="py-3 border px-3 rounded bg-title mt-1">
            <h1 className="text-white">
              <strong className="uppercase">{noticia.title}</strong>
            </h1>
          </div>
          <div className="">
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={noticia.media.length > 2 ? "3" : "auto"}
              loop={true}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 120,
                modifier: 1,
                slideShadows: true,
              }}
              autoplay={{
                delay: 4000,
              }}
              pagination={true}
              modules={[EffectCoverflow, Pagination, Autoplay]}
              className="mySwiper"
            >
              {noticia.media.map((image, i) => {
                const storage = "https://bp.bomberosmanta.gob.ec/storage/news";
                const rutaImg =
                  storage + "/" + image.id + "/" + image.file_name;
                return (
                  <SwiperSlide key={image.id}>
                    <img
                      className="rounded max-h-full h-full"
                      src={rutaImg}
                      alt={image.uuid}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div>
            <br />
            <div className="px-3">
              <p
                className="mb-4 text-base text-neutral-600 dark:text-neutral-200"
                dangerouslySetInnerHTML={{ __html: noticia.content }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DetalleNoticia;
