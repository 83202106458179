import React from "react";

const Footer = () => {
  return (
    <div className="fixed h-15 w-full bottom-0 left-0 flex py-2 px-5" style={{ zIndex: 20, background: '#aa0000' }}>
      <div className="flex flex-1 justify-center items-center gap-4">
        <div>
          <img src="/img/clock-regular.svg" alt="" width={35} />
        </div>
        <div className="text-white text-sm gap-2 text-center">
          <h6 className="text-sm font-bold">Horarios de Atención</h6>
          <p className="text-sm ">Lunes a Viernes: 8:00 - 16:30</p>
        </div>
      </div>

      <div className="flex-1 justify-center items-center gap-4 hidden md:flex lg:flex xl:flex 2xl:flex">
        <div>
          <img src="/img/location-dot-solid.svg" alt="" width={30} />
        </div>
        <div className=" text-white text-sm gap-2 text-center">
          <h6 className="text-sm font-bold">Ubicación</h6>
          <p className="text-sm ">Calle 12 y Avenida 11</p>
        </div>
      </div>

      <div className="flex-1 justify-center items-center gap-4 hidden md:flex lg:flex xl:flex 2xl:flex">
        <div>
          <img src="/img/phone-solid.svg" alt="" width={30} />
        </div>
        <div className=" text-white text-sm gap-2 text-center">
          <h6 className="text-sm font-bold">Contacto</h6>
          <p className="text-sm ">05 262-1777</p>
        </div>
      </div>
      <div className="flex flex-1 justify-end gap-4">
        <div className="flex items-center gap-4">
          <a href="https://www.instagram.com/bomberosmantaec/?hl=es" target="_blank" rel="noreferrer">
            <img src="/img/square-instagram.svg" alt="" width={25} />
          </a>
          <a href="https://www.facebook.com/BomberosMantaEc/" target="_blank" rel="noreferrer">
            <img src="/img/square-facebook.svg" alt="" width={25} />
          </a>
          <a href="https://twitter.com/BomberosMantaEc" target="_blank" rel="noreferrer">
            <img src="/img/square-twitter.svg" alt="" width={25} />
          </a>
          <a href="https://www.youtube.com/@bomberosmantaec357/videos" target="_blank" rel="noreferrer">
            <img src="/img/square-youtube.svg" alt="" width={25} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
