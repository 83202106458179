import React, { useState } from "react";
import Layout from "../layout/Layout";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from "react";

const MySwal = withReactContent(Swal)

const initialState = {
  showFormulario: false,
  showAceptados: false,
  loading: false,

  identification_card: "",
  full_name: "",
  marital_status: "Soltero",
  gender: "Femenino",
  blood_type: "O+",
  allergy: "",
  education_level: "Primaria",
  training: "",
  photo_volunteer: "",
  email: "",
  cellphone: "",
  address: "",
  occupation: "Estudio",
  certified_bachelor: "",
  criminal_record: "",
  institutional_expulsion: "NO",
  birth_date: "",

  disability_charge: "NO",
  catastrophic_illness: "NO",
  conadis: "NO",
  conadis_file: "",
  covid_vaccination_file: "",
  institutional_expulsion_file: "",
  curriculum: "",

  name_reference_1: "",
  relationship_reference_1: "",
  phone_reference_1: "",

  name_reference_2: "",
  relationship_reference_2: "",
  phone_reference_2: "",

  voluntariosAceptados: [
    {
      full_name: 'NOMBRE APELLIDO'
    }
  ]
}

const Voluntarios = () => {
  const [state, setState] = useState(initialState);

  const [voluntariosAceptados, setVoluntariosAceptados] = useState([]);

  const readAceptados = async () => {
    setState({ ...state, loading: true });
    const url = "https://bp.bomberosmanta.gob.ec/api/voluntarios?aceptado=true&fields=full_name";
    const rawResponse = await fetch(url, {
      method: 'GET',
      headers: {
        'X-requested-With': 'XMLHttpRequest',
      }
    });
    if (rawResponse.ok) {
      const content = await rawResponse.json();
      setState({ ...state, loading: false });
      setVoluntariosAceptados(content);
    } else {
      setVoluntariosAceptados([]);
      setState({ ...state, loading: false });
    }
  }

  useEffect(() => {
    readAceptados()
  }, [])

  const showTable = () => {
    const tableRows = [];
    for (let i = 0; i < voluntariosAceptados.length; i = i + 3) {
      tableRows.push(
        <tr key={voluntariosAceptados[i] + "-" + voluntariosAceptados[i + 1] + "-" + voluntariosAceptados[i + 2] + "-" + i}>
          <td>
            {i + 1}.- {voluntariosAceptados[i]['full_name']}
          </td>
          <td>
            {i + 2}.- {voluntariosAceptados[i + 1]['full_name']}
          </td>
          <td>
            {i + 3}.- {voluntariosAceptados[i + 2]['full_name']}
          </td>
        </tr>
      );
    }
    return tableRows;
  }


  const guardar = async () => {

    setState({ ...state, loading: true });

    /** validate  */
    for (const key in state) {
      if (Object.hasOwnProperty.call(state, key)) {
        const element = state[key];
        const nombre = document.getElementsByName(key)[0];
        if (nombre !== undefined) {
          const nombrecampo = nombre.getAttribute("nombrecampo");
          if (key !== 'conadis_file') {
            if (element === undefined || element.toString().trim() === "") {
              const mensaje = `El campo ${nombrecampo} es obligatorio.`;
              setState({ ...state, loading: false })
              MySwal.fire({
                title: <strong>Alerta!</strong>,
                html: <i>{mensaje}</i>,
                icon: 'warning',
                timer: 3000,
                toast: true,
                position: 'bottom-end',
                timerProgressBar: true,
                showConfirmButton: false
              });
              return;
            }
          }
        }
      }
    }
    /** validate  */

    const url = "https://bp.bomberosmanta.gob.ec/api/voluntario";
    const form = document.querySelector('form');
    const rawResponse = await fetch(url, {
      method: 'POST',
      headers: {
        'X-requested-With': 'XMLHttpRequest',
      },
      body: new FormData(form)
    });

    if (rawResponse.ok) {
      const content = await rawResponse.json();

      MySwal.fire({
        title: <strong>Alerta!</strong>,
        text: content.message,
        icon: 'success',
      });

      form.reset();

      setTimeout(() => {
        setState(initialState)
      }, 1200);

    } else {
      const content = await rawResponse.json();
      setTimeout(() => {
        setState({ ...state, loading: false })
      }, 1200);

      if (content.status !== 500) {
        MySwal.fire({
          title: <strong>Alerta!</strong>,
          text: content.message,
          icon: 'warning',
        });
      } else {
        MySwal.fire({
          title: <strong>Alerta!</strong>,
          text: "Hubo un inconveniente, vuelva a reintentar.",
          icon: 'warning',
        });
      }

    }

  }


  return (
    initialState.showFormulario ?
      <Layout>
        <h1 className="text-center text-red-700 font-bold text-xl mt-4">
          Registro de Voluntarios
        </h1>
        <div className="bg-red-700 text-white py-2 px-4 mr-8 ml-8 font-bold border border-2 border-black rounded-md mt-4">
          El curso es gratuito. Sin embargo el postulante deberá conocer que, para participar en el
          curso de Ingreso a Bomberos Voluntario deberá contar con un seguro de vida personal,
          uniforme, alimentación y disponibilidad de tiempo para sujetarse a los cronogramas del proceso.<br />
          <strong className="mr-1">NOTA:</strong>
          Este proceso esta sujeto de acuerdo a los siguientes
          <strong>
            <a href="./marco_legal.pdf" target="_blank" rel="noreferrer" className="ml-1">
              TÉRMINOS LEGALES
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-1" />
            </a>
          </strong>
        </div>

        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" id="formularioDatos" encType="multipart/form-data" name="formularioDatos" method="POST">
          <div className="flex px-8">
            <div className="flex-col flex-1 mr-8">
              {/* <h1 className="font-bold mb-4">Datos personales</h1> */}
              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="cIdentidad">Cédula de Identidad</label>
                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="cIdentidad" type="text" placeholder="Cédula de Identidad" value={state.identification_card} nombrecampo="Cédula de identidad"
                  onChange={(event) => setState({ ...state, identification_card: event.target.value })} name="identification_card" />
              </div>
              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="NombresCompletos">Nombres Completos</label>
                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="NombresCompletos" type="text" placeholder="Nombres Completos" value={state.full_name} nombrecampo="Nombres Completos"
                  onChange={(event) => setState({ ...state, full_name: event.target.value })} name="full_name" />
              </div>
              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="foto">
                  Foto
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="foto" type="file" value={state.photo_volunteer} onChange={(event) => setState({ ...state, photo_volunteer: event.target.value })}
                  name="photo_volunteer" accept="image/png, image/gif, image/jpeg" nombrecampo="Foto" />
              </div>
              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="birth_date">Fecha Nacimiento</label>
                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="birth_date" type="date" placeholder="Nombres Completos" value={state.birth_date} nombrecampo="Fecha de Nacimiento"
                  onChange={(event) => setState({ ...state, birth_date: event.target.value })} name="birth_date" />
              </div>
              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="EstadoCivil">Estado Civil</label>
                <select id="EstadoCivil" value={state.marital_status} onChange={(event) => setState({ ...state, marital_status: event.target.value })}
                  className="form-select shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  aria-label="Default select example" name="marital_status" nombrecampo="Estado Civil">
                  <option value="Soltero">Soltero(a)</option>
                  <option value="Casado">Casado(a)</option>
                  <option value="Unión Libre">Unión Libre</option>
                  <option value="Divorciado">Divorciado(a)</option>
                  <option value="Viudo">Viudo(a)</option>
                </select>
              </div>

              <div className="mb-4 flex items-center">
                <label className="w-40 mr-2 text-gray-700 text-sm font-bold ">
                  Género
                </label>
                <select value={state.gender} id="genero" onChange={(event) => setState({ ...state, gender: event.target.value })}
                  className="form-select shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  aria-label="Default select example" name="gender" nombrecampo="Género">
                  <option value="Masculino">Masculino</option>
                  <option value="Femenino">Femenino</option>
                  <option value="Otro">Otro</option>
                </select>
              </div>

              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="Nivelinstruccion">
                  Nivel de instrucción
                </label>
                <select id="nivelInstruccion" value={state.education_level} onChange={(event) => setState({ ...state, education_level: event.target.value })}
                  className="form-select shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  aria-label="Default select example" name="education_level" nombrecampo="Nivel de instrucción">
                  <option value="Primaria">Primaria</option>
                  <option value="Secundaria">Secundaria</option>
                  <option value="Tercer Nivel">Tercer Nivel</option>
                  <option value="Cuarto Nivel">Cuarto Nivel</option>
                  <option value="PhD">PhD</option>
                </select>
              </div>

              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="Certificadobachiller">Certificado de Bachillerato</label>
                <input name="certified_bachelor"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="bachillerato" type="file" accept="application/pdf" value={state.certified_bachelor} nombrecampo="Certificado de Bachillerato"
                  onChange={(event) => setState({ ...state, certified_bachelor: event.target.value })} />
              </div>

              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="Domicilio">Domicilio</label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="Domicilio" type="text" placeholder="Domicilio" value={state.address} nombrecampo="Domicilio"
                  onChange={(event) => setState({ ...state, address: event.target.value })} name="address" />
              </div>
              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="GrupoSanguineo">
                  Grupo Sanguíneo
                </label>
                <select value={state.blood_type} onChange={(event) => setState({ ...state, blood_type: event.target.value })}
                  className="form-select shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  aria-label="Default select example" name="blood_type" nombrecampo="Grupo Sanguíneo">
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                </select>
              </div>

              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="Alergias">
                  Alergias
                </label>
                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="Alergias" type="text" placeholder="Alergias" value={state.allergy} name="allergy" nombrecampo="Alergias"
                  onChange={(event) => setState({ ...state, allergy: event.target.value })} />
              </div>

              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="Cursosrealizados">Cursos realizados</label>
                <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="Cursosrealizados" type="text" placeholder="Cursos realizados" value={state.training} nombrecampo="Cursos realizados"
                  onChange={(event) => setState({ ...state, training: event.target.value })} name="training"></textarea>
              </div>
            </div>

            <div className="flex-col flex-1 ml-8">
              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="Correoelectronico">Correo electrónico</label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="Correoelectronico" type="text" placeholder="Correo electrónico" value={state.email} nombrecampo="Correo electrónico"
                  onChange={(event) => setState({ ...state, email: event.target.value })} name="email" />
              </div>
              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="telefono">Teléfono</label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="telefono" type="text" placeholder="Teléfono" value={state.cellphone} nombrecampo="Teléfono"
                  onChange={(event) => setState({ ...state, cellphone: event.target.value })} name="cellphone" />
              </div>

              <div className="mb-4 flex items-center">
                <label className="w-40 mr-2 text-gray-700 text-sm font-bold ">
                  Ocupación
                </label>
                <select id="occupation" value={state.occupation} onChange={(event) => setState({ ...state, occupation: event.target.value })}
                  className="form-select shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  aria-label="Default select example" name="occupation" nombrecampo="Ocupación">
                  <option value="Estudio">Estudio</option>
                  <option value="Trabajo">Trabajo</option>
                  <option value="Estudio y trabajo">Estudio y trabajo</option>
                </select>
              </div>

              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="curriculum">
                  Currículum Vitae
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="curriculum" type="file" value={state.curriculum} name="curriculum" accept="application/pdf"
                  onChange={(event) => setState({ ...state, curriculum: event.target.value })} nombrecampo="Curriculum Vitae" />
              </div>

              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="antecedentes">
                  Certificado de no antecedentes penales
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="antecedentes" type="file" value={state.criminal_record} name="criminal_record" accept="application/pdf"
                  onChange={(event) => setState({ ...state, criminal_record: event.target.value })} nombrecampo="Certificado de no tener antecedentes" />
              </div>

              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="institutional_expulsion_file">
                  Certificado de no haber sido dado de baja
                </label>
                <div className="w-full">
                  <input
                    className="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="institutional_expulsion_file" type="file" value={state.institutional_expulsion_file} name="institutional_expulsion_file" accept="application/pdf"
                    onChange={(event) => setState({ ...state, institutional_expulsion_file: event.target.value })} nombrecampo="Certificado de no haber sido dado de baja" />
                  <div>
                    <span style={{ "fontSize": "8pt", "color": "red", "marginLeft": "6pt" }}>Certificado de Policía Nacional, Fuerzas Armadas, y Comisión de Tránsito</span>
                  </div>
                </div>
              </div>


              <div className="mb-4 flex items-center">
                <label className="w-75 text-gray-700 text-sm font-bold mr-2">
                  Ha sido dado de baja de alguna institución?
                </label>
                <div className="px-1">
                  <input type="radio" value="on" name="institutional_expulsion" checked={state.institutional_expulsion === 'SI'} className="mr-1" nombrecampo="Ha sido dado de baja en alguna insitución"
                    onChange={(event) => setState({ ...state, institutional_expulsion: "SI" })} />
                  <label htmlFor="si">SI</label>
                </div>
                <div className="px-1">
                  <input type="radio" value="off" name="institutional_expulsion" checked={state.institutional_expulsion === 'NO'} className="mr-1" nombrecampo="Ha sido dado de baja en alguna insitución"
                    onChange={(event) => setState({ ...state, institutional_expulsion: "NO" })} />
                  <label htmlFor="no">NO</label>
                </div>
              </div>

              <div className="mb-4 flex items-center">
                <label className="w-75 text-gray-700 text-sm font-bold mr-2">
                  ¿Tiene alguna enfermedad clínica, catatrósfica o de situaciones que
                  impida realizar actividades físicas de alta demanda?
                </label>
                <div className="px-1 flex flex-row">
                  <input type="radio" value="on" name="catastrophic_illness" checked={state.catastrophic_illness === 'SI'} className="mr-1" nombrecampo="¿Tiene alguna enfermedad clínica, catatrósfica o de situaciones que
                impida realizar actividades físicas de alta demanda?"
                    onChange={(event) => setState({ ...state, catastrophic_illness: "SI" })} />
                  <label htmlFor="si">SI</label>
                </div>
                <div className="px-1 flex flex-row">
                  <input type="radio" value="off" name="catastrophic_illness" checked={state.catastrophic_illness === 'NO'} className="mr-1" nombrecampo="¿Tiene alguna enfermedad clínica, catatrósfica o de situaciones que
                impida realizar actividades físicas de alta demanda?"
                    onChange={(event) => setState({ ...state, catastrophic_illness: "NO" })} />
                  <label htmlFor="no">NO</label>
                </div>
              </div>

              <div className="mb-4 flex items-center">
                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="covid_vaccination_file">
                  Certificado de COVID
                </label>
                <div className="w-full">
                  <input
                    className="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="covid_vaccination_file" type="file" value={state.covid_vaccination_file} name="covid_vaccination_file" accept="application/pdf"
                    onChange={(event) => setState({ ...state, covid_vaccination_file: event.target.value })} nombrecampo="Certificado de COVID" />
                  <div>
                    <span style={{ "fontSize": "8pt", "color": "red", "marginLeft": "6pt" }}>Certificado con 3ra dosis</span>
                  </div>
                </div>
              </div>

              <div className="mb-4 flex items-center">
                <label className="w-75 text-gray-700 text-sm font-bold mr-2">
                  ¿Tiene usted algún familiar con capacidades especiales a cargo?
                </label>
                <div className="px-1 flex flex-row">
                  <input type="radio" value="on" name="disability_charge" checked={state.disability_charge === 'SI'} className="mr-1" nombrecampo="¿Tiene usted algun familiar a cargo?"
                    onChange={(event) => setState({ ...state, disability_charge: "SI" })} />
                  <label htmlFor="si">SI</label>
                </div>
                <div className="px-1 flex flex-row">
                  <input type="radio" value="off" name="disability_charge" checked={state.disability_charge === 'NO'} className="mr-1" nombrecampo="¿Tiene usted algun familiar a cargo?"
                    onChange={(event) => setState({ ...state, disability_charge: "NO" })} />
                  <label htmlFor="no">NO</label>
                </div>
              </div>

              <div className="mb-4 flex items-center">
                <label className="w-75 text-gray-700 text-sm font-bold mr-2">
                  ¿Cuenta usted con carnet CONADIS?
                </label>
                <div className="px-1 flex flex-row">
                  <input type="radio" value="on" name="conadis" checked={state.conadis === 'SI'} className="mr-1" nombrecampo="¿Tiene usted algun familiar a cargo?"
                    onChange={(event) => setState({ ...state, conadis: "SI" })} />
                  <label htmlFor="si">SI</label>
                </div>
                <div className="px-1 flex flex-row">
                  <input type="radio" value="off" name="conadis" checked={state.conadis === 'NO'} className="mr-1" nombrecampo="¿Tiene usted algun familiar a cargo?"
                    onChange={(event) => setState({ ...state, conadis: "NO" })} />
                  <label htmlFor="no">NO</label>
                </div>
              </div>

              {(state.conadis === 'SI') ?
                <div className="mb-4 flex items-center">
                  <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="conadis_file">
                    Carnet de CONADIS
                  </label>
                  <div className="w-full">
                    <input
                      className="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="antecedentes" type="file" value={state.conadis_file} name="conadis_file" accept="application/pdf"
                      onChange={(event) => setState({ ...state, conadis_file: event.target.value })} nombrecampo="Carnet de CONADIS" />
                  </div>
                </div>
                : ""
              }


            </div>
          </div>

          <div className="flex px-8 mt-2">
            <div className="flex-col flex-1">
              <h1 className="font-bold mb-4">Referencias personales</h1>
              <div className="flex flex-row">
                <div className="mb-4 items-center flex-1">
                  <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="NombresReferencias">
                    Nombres
                  </label>
                  <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="NombresReferencias1" type="text" placeholder="Nombres" value={state.name_reference_1} nombrecampo="Nombres"
                    onChange={(event) => setState({ ...state, name_reference_1: event.target.value })} name="name_reference_1" />
                </div>
                <div className="mb-4 items-center px-2">
                  <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="Parentesco1">
                    Parentesco
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="Parentesco1" type="text" placeholder="Parentesco" value={state.relationship_reference_1} name="relationship_reference_1"
                    onChange={(event) => setState({ ...state, relationship_reference_1: event.target.value })} nombrecampo="Parentesco" />
                </div>
                <div className="mb-4 items-center">
                  <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="Telefono1">
                    Teléfono
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="Telefono1" type="text" placeholder="Teléfono" value={state.phone_reference_1} name="phone_reference_1"
                    onChange={(event) => setState({ ...state, phone_reference_1: event.target.value })} nombrecampo="Teléfono" />
                </div>
              </div>
              <div className="flex flex-row">
                <div className="mb-4 items-center flex-1">
                  <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="NombresReferencias2">
                    Nombres
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="NombresReferencias2" type="text" placeholder="Nombres" value={state.name_reference_2} name="name_reference_2"
                    onChange={(event) => setState({ ...state, name_reference_2: event.target.value })} nombrecampo="Nombres" />
                </div>
                <div className="mb-4 items-center px-2">
                  <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="Parentesco2">
                    Parentesco
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="Parentesco2" type="text" placeholder="Parentesco" value={state.relationship_reference_2} name="relationship_reference_2"
                    onChange={(event) => setState({ ...state, relationship_reference_2: event.target.value })} nombrecampo="Parentesco" />
                </div>
                <div className="mb-4 items-center">
                  <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="Telefono2">
                    Teléfono
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="Telefono2" type="text" placeholder="Teléfono" value={state.phone_reference_2} name="phone_reference_2"
                    onChange={(event) => setState({ ...state, phone_reference_2: event.target.value })} nombrecampo="Teléfono" />
                </div>
              </div>
            </div>
          </div>

          <div className="px-8">
            <div className="flex items-center justify-end pb-10 mb-5">
              {state.loading === true ?
                <button className="bg-blue-300 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button" onClick={guardar} disabled="disable">
                  <span>Guardando...!</span>
                </button>
                :
                <button className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button" onClick={guardar}>
                  <span>Registrar</span>
                </button>
              }

            </div>
          </div>
        </form>
      </Layout >
      :
      (
        initialState.showAceptados ?
          <Layout>
            <h1 className="text-center text-red-700 font-bold text-xl mt-4">
              Nómina de aspirantes a bomberos VIII promoción aprobados, a la Segunda Fase  de seleccion 2023
            </h1>
            <div className="text-gray-700 py-2 px-4 mr-8 ml-8 font-bold border border-2 border-sky-600 rounded-md mt-4 mb-10" style={{ marginBottom: '100px' }}>
              <table className="mb-1" style={{ width: '100%', textTransform: 'uppercase' }}>
                <tbody>
                  {initialState.loading ? (
                    <tr>
                      <td colSpan={2}>Cargando...</td>
                    </tr>
                  ) : (
                    showTable()
                  )}
                </tbody>
              </table>
              <br />
            </div>
            <br />
          </Layout>
          :
          <Layout>
            <h1 className="text-center text-red-700 font-bold text-xl mt-4">
              Registro de Voluntarios
            </h1>
            <div className="bg-lime-700 text-white py-2 px-4 mr-8 ml-8 font-bold border border-2 border-black rounded-md mt-4">
              El plazo para el registro de voluntarios ya finalizó.<br />
            </div>
          </Layout>
      )

  );

};

export default Voluntarios;
