import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { useParams } from "react-router-dom";
import CardNoticias from "../../components/CardNoticias";

const Noticias = () => {
  let { categoria } = useParams();
  const [textCategoria, setTextCategorias] = useState("Noticias");
  const [noticias, setNoticias] = useState([]);
  const readCategoria = async () => {
    if (categoria) {
      const url =
        "https://bp.bomberosmanta.gob.ec/api/categorias-news/show/" + categoria;
      const rawResponse = await fetch(url, {
        method: "GET",
        headers: {
          "X-requested-With": "XMLHttpRequest",
        },
      });
      if (rawResponse.ok) {
        const content = await rawResponse.json();
        setTextCategorias(content.name);
      } else {
        setTextCategorias("Noticias");
      }
    }
  };

  const readNoticias = async () => {
    const _categoria = categoria ?? 0;
    const url = "https://bp.bomberosmanta.gob.ec/api/list-news/" + _categoria;
    const rawResponse = await fetch(url, {
      method: "GET",
      headers: {
        "X-requested-With": "XMLHttpRequest",
      },
    });
    if (rawResponse.ok) {
      const content = await rawResponse.json();
      setNoticias(content);
    } else {
      setNoticias([]);
    }
  };

  useEffect(() => {
    readCategoria();
    readNoticias();
  }, []);

  return (
    <Layout>
      <div className="h-[20rem]">
        <div className="w-full h-full">
          <img
            className="h-full w-full"
            src="/img/noticias/cover.jpeg"
            alt=""
            style={{
              height: "100%",
              objectFit: "cover",
              objectPosition: "50% 30%",
            }}
          />
        </div>
        <div className="w-full flex justify-center relative">
          <div className="w-3/4 -top-10 bg-black opacity-85 absolute py-4 rounded-md text-center text-4xl text-white font-bold">
            {textCategoria}
          </div>
        </div>
        <div className="mt-20 mb-10 px-8 pb-[90pt] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xxl:grid-cols-5 gap-8">
          {noticias?.map((noticia, index) => {
            return (
              <div className="col" key={index}>
                <CardNoticias
                  id={noticia.id}
                  firstImage={noticia?.media?.[0]?.id}
                  title={noticia?.title}
                  content={noticia?.content}
                  img={noticia?.media?.[0]?.file_name}
                  key={index}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default Noticias;
