import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../components/Tooltip";

import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';


const initialState = {
    loading: false,
    anios: [2021, 2022, 2023, 2024],
    rutaStorage: "https://bp.bomberosmanta.gob.ec/storage/transparency/"
}

const BaseLegal = () => {

    const [state, setState] = useState(initialState);
    const [docs, setDocs] = useState([]);

    const readDatabase = async () => {
        setState({ ...state, loading: true });
        const url = "https://bp.bomberosmanta.gob.ec/api/getBaseLegal";
        const rawResponse = await fetch(url, {
            method: 'GET',
            headers: {
                'X-requested-With': 'XMLHttpRequest',
            }
        });
        if (rawResponse.ok) {
            const content = await rawResponse.json();
            setState({ ...state, loading: false });
            setDocs(content.registros);
        } else {
            setDocs([]);
            setState({ ...state, loading: false });
        }
    }

    useEffect(() => {
        readDatabase()
    }, [])

    const CardDoc = ({ title, file, color, index }) => {
        return (
            <div className="p-4 rounded-lg shadow border col-span-1 backdrop-blur-lg" key={index}>
                <div className="flex flex-col h-full">
                    <div className="flex flex-row w-full">
                        <a href={state.rutaStorage + file} target="_blank" rel="noreferrer">
                            <Tooltip text="Visualizar documento">
                                <img src="./img/icons/law.png" alt={title} style={{ "width": "85pt" }} />
                            </Tooltip>
                        </a>
                        <div className="flex justify-end w-full">
                            <Tooltip text="Copiar Enlace">
                                <FontAwesomeIcon icon={faCopy} className="fa-1x text-red-600 hover:text-sky-400" style={{ 'cursor': 'pointer' }} onClick={(props) => {
                                    navigator.clipboard.writeText(state.rutaStorage + file)
                                        .then(() => {
                                            alertify.success('<span style="color:white">Enlace copiado </span>');
                                        }, () => {
                                            console.error('Error al copiar');
                                        });
                                }} />
                            </Tooltip>
                        </div>
                    </div>
                    <div className="h-full flex items-end mt-8">
                        <span className="text-stone-700 capitalize" style={{ "fontFamily": "Montserrat", "letterSpacing":"-0.5pt" }}>{title}</span>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Layout>
            <div className="w-full" style={{ "background": "url('./img/background-base-legal.jpg') no-repeat center center", "backgroundSize":"cover"}}>
                <div className="pt-5" style={{ "position": "sticky", "top": "5px", "zIndex": 1 }}>
                    <div className="border rounded mx-5 p-2 shadow bg-rose-800 text-white" >
                        <h3 className="font-bold text-center text-3xl">
                            Base Legal
                        </h3>
                    </div>
                </div>

                <div className="mt-10 px-8" style={{ "paddingBottom": "140px" }}>
                    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-6 grid-flow-row gap-4 bg-stripes-pink">
                        {docs?.map((documento, index) => {
                            return (
                                <CardDoc title={documento.title} index={index} key={index} file={documento.file} />
                            )
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default BaseLegal;