import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Layout from "../layout/Layout";

// Initialization for ES Users
// import {
//     Input,
//     initTE,
// } from "tw-elements";

// initTE({ Input });

const MySwal = withReactContent(Swal);

// • Promoción de “Bombero Brigadista” – Escoger entre 1ra., 2da. o 3ra
// • Nivel de Educación.
// • Profesión y/o Ocupación.
const initialState = {
    showFormulario: true,
    showAceptados: true,
    loading: false,
    // campos formulario
    identificacion: '',
    fullName: '',
    fechaNacimiento: '',
    cellphone: '',
    email: '',
    domicilio: '',
    promocion: '1',
    nivel_educacion: 'Primaria',
    ocupacion: '',
}


const Brigadistas = () => {
    const [state, setState] = useState(initialState);

    const guardar = async () => {
        setState({ ...state, loading: true });

        /** validate  */
        for (const key in state) {
            if (Object.hasOwnProperty.call(state, key)) {
                const element = state[key];
                const nombre = document.getElementsByName(key)[0];
                if (nombre !== undefined) {
                    const nombrecampo = nombre.getAttribute("nombrecampo");

                    if (element === undefined || element.toString().trim() === "") {
                        const mensaje = `El campo ${nombrecampo} es obligatorio.`;
                        setState({ ...state, loading: false })
                        MySwal.fire({
                            title: <strong>Alerta!</strong>,
                            html: <i>{mensaje}</i>,
                            icon: 'warning',
                            timer: 3000,
                            toast: true,
                            position: 'bottom-end',
                            timerProgressBar: true,
                            showConfirmButton: false
                        });
                        return;
                    }
                }
            }
        }
        /** validate  */

        // const url = "http://127.0.0.1:8000/api/brigadistas/create";
        const url = "https://bp.bomberosmanta.gob.ec/api/brigadistas/create";
        const form = document.querySelector('form');
        const rawResponse = await fetch(url, {
            method: 'POST',
            headers: {
                'X-requested-With': 'XMLHttpRequest',
            },
            body: new FormData(form)
        });

        if (rawResponse.ok) {
            const content = await rawResponse.json();

            MySwal.fire({
                title: <strong>Alerta!</strong>,
                text: content.message,
                icon: 'success',
            });

            form.reset();

            setTimeout(() => {
                setState(initialState)
            }, 1200);

        } else {
            const content = await rawResponse.json();
            setTimeout(() => {
                setState({ ...state, loading: false })
            }, 1200);

            if (content.status !== 500) {
                MySwal.fire({
                    title: <strong>Alerta!</strong>,
                    text: content.message,
                    icon: 'warning',
                });
            } else {
                MySwal.fire({
                    title: <strong>Alerta!</strong>,
                    text: "Hubo un inconveniente, vuelva a reintentar.",
                    icon: 'warning',
                });
            }

        }
    }

    return (
        <Layout>
            <h1 className="text-center text-red-700 font-bold text-xl mt-4">
                Registro de Brigadistas
            </h1>
            <div className="w-full">
                <form className="bg-white shadow-md rounded px-8 pt-6 pb-10 mb-5 mx-5" id="formularioDatos" encType="multipart/form-data" name="formularioDatos" method="POST">
                    <div className="grid grid-flow-col overflow-x-auto">
                        <div className="flex px-2 flex-col">
                            <div className="mb-4 flex flex-col justify-start content-center">
                                <label className="w-60 text-gray-700 text-sm font-bold px-1 py-1" htmlFor="identificacion">Cédula de Identidad</label>
                                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="identificacion" type="text" placeholder="Digite su cedula de identidad" value={state.identificacion} nombrecampo="Cédula de identidad"
                                    onChange={(event) => setState({ ...state, identificacion: event.target.value })} name="identificacion" maxLength={13} onKeyDown={function (e) {
                                        if (e.keyCode === '189') {
                                            e.preventDefault()
                                        }
                                    }} />
                            </div>
                            <div className="mb-4 flex flex-col justify-start content-center">
                                <label className="w-60 text-gray-700 text-sm font-bold px-1 py-1" htmlFor="fullName">Nombres completos</label>
                                <input className="uppercase shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="fullName" type="text" placeholder="" value={state.fullName} nombrecampo="Nombres Completos"
                                    onChange={(event) => setState({ ...state, fullName: event.target.value })} name="fullName" />
                            </div>
                            <div className="mb-4 flex flex-col justify-start content-center">
                                <label className="w-60 text-gray-700 text-sm font-bold px-1 py-1" htmlFor="fechaNacimiento">Fecha Nacimiento</label>
                                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="fechaNacimiento" type="date" value={state.fechaNacimiento} nombrecampo="Fecha de Nacimiento"
                                    onChange={(event) => setState({ ...state, fechaNacimiento: event.target.value })} name="fechaNacimiento" />
                            </div>
                            <div className="mb-4 flex flex-col justify-start content-center">
                                <label className="w-40 text-gray-700 text-sm font-bold px-1 py-1" htmlFor="email">Correo electrónico</label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email" type="text" placeholder="correo@correo.com" value={state.email} nombrecampo="Correo electrónico"
                                    onChange={(event) => setState({ ...state, email: event.target.value })} name="email" />
                            </div>
                            <div className="mb-4 flex flex-col justify-start content-center">
                                <label className="w-40 text-gray-700 text-sm font-bold px-1 py-1" htmlFor="cellphone">Teléfono</label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="cellphone" type="text" value={state.cellphone} nombrecampo="Teléfono"
                                    onChange={(event) => setState({ ...state, cellphone: event.target.value })} name="cellphone" />
                            </div>
                        </div>
                        <div className="flex px-2 flex-col">
                            <div className="mb-4 flex-col justify-start content-center">
                                <label className="w-40 text-gray-700 text-sm font-bold px-1 py-1" htmlFor="nivel_educacion">
                                    Nivel de instrucción
                                </label>
                                <select id="nivel_educacion" value={state.nivel_educacion} onChange={(event) => setState({ ...state, nivel_educacion: event.target.value })}
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    aria-label="Default select" name="nivel_educacion" nombrecampo="Nivel de instrucción">
                                    <option value="Primaria">Primaria</option>
                                    <option value="Secundaria">Secundaria</option>
                                    <option value="Tercer Nivel">Tercer Nivel</option>
                                    <option value="Cuarto Nivel">Cuarto Nivel</option>
                                    <option value="PhD">PhD</option>
                                </select>
                            </div>
                            <div className="mb-4 flex-col justify-start content-center">
                                <label className="w-40 text-gray-700 text-sm font-bold px-1 py-1" htmlFor="promocion">
                                    Promoción
                                </label>
                                <select id="promocion" value={state.promocion} onChange={(event) => setState({ ...state, promocion: event.target.value })}
                                    className="form-select shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    aria-label="Default select" name="promocion" nombrecampo="Promoción">
                                    <option value="1">Primera</option>
                                    <option value="2">Segunda</option>
                                    <option value="3">Tercera</option>
                                </select>
                            </div>
                            <div className="mb-4 flex-col justify-start content-center">
                                <label className="w-40 text-gray-700 text-sm font-bold px-1 py-2" htmlFor="ocupacion">Ocupación</label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="ocupacion" type="text" placeholder="Digite su ocupacion" value={state.ocupacion} nombrecampo="Ocupación"
                                    onChange={(event) => setState({ ...state, ocupacion: event.target.value })} name="ocupacion" />
                            </div>
                            <div className="mb-4 flex-col justify-start content-center">
                                <label className="w-40 text-gray-700 text-sm font-bold mr-2" htmlFor="domicilio">Domicilio</label>
                                <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="domicilio" type="text" placeholder="Digite la direccion de su domicilio" value={state.domicilio} nombrecampo="Domicilio"
                                    onChange={(event) => setState({ ...state, domicilio: event.target.value })} name="domicilio"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="px-8 border-t-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50">
                        <div className="flex items-center justify-end pb-1 mb-5">
                            {state.loading === true ?
                                <button className="bg-blue-300 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="button" disabled="disable">
                                    <span>Guardando...!</span>
                                </button>
                                :
                                <button className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="button" onClick={guardar}>
                                    <span>Registrar</span>
                                </button>
                            }

                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    );
}

export default Brigadistas;