import React, { useEffect, useState } from "react";
import CardPeriodo from "../components/CardPeriodo";
import Layout from "../layout/Layout";
import { faCaretLeft, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const initialState = {
  categoria: {
    id: 3,
    map: null,
    valor: 'Rendición de Cuentas',
  },
  loading: false,
  anios: [2019, 2020, 2021, 2022,2023],
  anioActual: null,
  months: [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ],
  rutaStorage: "https://bp.bomberosmanta.gob.ec/storage/transparency/"
  // rutaStorage: "http://127.0.0.1:8001/storage/transparency/"
}

const RendicionCuentas = () => {

  const [state, setState] = useState(initialState);

  const [docs, setDocs] = useState([]);

  const readDatabase = async () => {
    setState({ ...state, loading: true });
    const url = "https://bp.bomberosmanta.gob.ec/api/getTransparencia";
    // const url = "http://127.0.0.1:8001/api/getTransparencia";
    const rawResponse = await fetch(url, {
      method: 'GET',
      headers: {
        'X-requested-With': 'XMLHttpRequest',
      }
    });
    if (rawResponse.ok) {
      const content = await rawResponse.json();
      setState({ ...state, loading: false });
      setDocs(content.registros);
    } else {
      setDocs([]);
      setState({ ...state, loading: false });
    }
  }


  const visualizarPeriodo = async (anio) => {
    setState({
      ...state, anioActual: {
        anio: anio,
        documentos: docs[anio]
      }
    });
  }

  const resetearPeriodo = async () => {
    setState({ ...state, anioActual: null })
  }

  useEffect(() => {
    readDatabase()
  }, [])


  const anioItems = state.anios.map((anio, index) =>
    <div className="col-span-1" key={index} onClick={() => visualizarPeriodo(anio)}>
      <CardPeriodo title={"Período " + anio} />
    </div>
  );

  const mesesItem = (categoria) => {
    if (state.anioActual.documentos !== undefined && state.anioActual.documentos[categoria.valor] !== undefined) {
      return state.anioActual.documentos[categoria.valor].map((doc, index) =>
        <div key={index}>
          {doc.type_file === 'IMAGE' ?
            <div className="flex justify-center">
              <div className="rounded-lg shadow p-2 border" style={{ width: '70%' }}>
                <span className="capitalize font-semibold ml-1 text-gray-500 italic">{doc.title}</span>
                <hr className="mb-2" />
                <img src={state.rutaStorage + doc.file} alt="Imagen" className="rounded" />
              </div>
            </div>
            :
            (doc.type_file === 'VIDEO' ?
              <div className="flex justify-center my-2">
                <div className="rounded-lg shadow p-2 border" style={{ width: '80%' }}>
                  <span className="capitalize font-semibold ml-1 text-gray-500 italic px-2">{doc.title}</span>
                  <hr className="mx-2 mb-2" />
                  <div className="flex justify-center px-2">
                    <iframe width="100%" height="465" src={doc.file} title="Video"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>
                </div>
              </div>
              :
              <li >
                <a className="hover:text-blue-600 w-100 capitalize no-underline hover:underline decoration-sky-500" target='_blank' href={state.rutaStorage + doc.file} rel="noreferrer">
                  {doc.title}
                </a>
              </li>
            )
          }
        </div>
      );
    } else {
      return <div className="w-full bg-gray-100 text-center rounded py-1">
        <span className="text-lg font-medium">Sin registros.</span>
      </div>
    }
  }

  const detalleAnios = () => {
    return (
      <div>
        <div className="flex w-full bg-slate-400 rounded-md py-2">
          <div className="flex-auto w-14 px-2">
            <button className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-1 px-3 rounded focus:outline-none focus:shadow-outline" onClick={resetearPeriodo}>
              <FontAwesomeIcon icon={faCaretLeft} className="mr-1" />Volver
            </button>
          </div>
          <div className="flex-auto w-80 text-center">
            <h1 className="text-xl font-bold text-white">Período {state.anioActual.anio}</h1>
          </div>
          <div className="flex-auto w-14 text-right px-2">
          </div>
        </div>
        <div className="border mt-2 rounded p-2">
          <div className="py-2 px-5">
            <div className="text-zinc-600 text-base">
              {mesesItem(initialState.categoria)}
            </div>
          </div>
        </div>
      </div>
    );

  }


  return (
    <Layout>
      <div className="h-full">
        <div className="w-full h-96">
          <img className="h-full w-full" src="./img/transparencia2024.jpg" alt="" style={{ "height": "100%", "objectFit": "cover", "objectPosition": "50% 40%" }} />
        </div>
        <div className="w-full flex justify-center relative">
          <div className="w-3/4 -top-12 bg-black shadow shadow-gray-500/40 opacity-[.85] absolute py-5 rounded-md text-center text-4xl text-white font-bold">
            Rendición de Cuentas
          </div>
        </div>
        {
          state.loading ?
            <div className="flex justify-center items-center mt-20">
              <FontAwesomeIcon icon={faSpinner} className="mr-1 text-gray-500" pulse size="2x" />
            </div>
            :
            <div className="mt-20 px-8 pb-[80pt]">
              {
                state.anioActual !== null ?
                  detalleAnios()
                  :
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {anioItems}
                  </div>
              }
            </div>
        }
      </div>
    </Layout>
  );
};

export default RendicionCuentas;
