import React from "react";
import Layout from "../layout/Layout";

const Simbolos = () => {
  return (
    <Layout>
      <div className="h-[28rem]">
        <div className="w-full h-full">
          <img className="h-full w-full" src="./img/simbolos.jpg" alt="" style={{ "height": "100%", "objectFit": "cover", "objectPosition": "50% 50%" }} />
        </div>
        <div className="w-full flex justify-center relative">
          <div className="w-3/4 -top-12 bg-black opacity-[.87] absolute py-8 rounded-md text-center text-4xl text-white font-bold">
            Símbolos
          </div>
        </div>
        <div className="mt-20 px-8 pb-[7rem]">
          <div className="flex gap-10 justify-center p-3">
            <div className="flex flex-row gap-5 rounded py-3 px-5 shadow-lg items-center">
              <img className="h-40 mt-4" src="./img/logo.png " alt="" />
              <div className="flex w-96 flex-col">
                <h3 className="text-red-700 font-bold text-xl mb-2">Escudo</h3>
                <p className="text-justify">
                  El Escudo oficial de los Cuerpos de Bomberos de la República
                  será: en el centro de un óvalo, otro más pequeño, y dentro de
                  este último el escudo de la ciudad o provincia.
                  En la parte posterior, en el centro, se colocará una
                  bocina de mando en posición vertical, con la campana hacia
                  abajo. En forma diagonal se colocará a la derecha un hacha de
                  pico, y a la izquierda un pitón cruzando horizontalmente una
                  escala.
                </p>
              </div>
            </div>
            <div className="flex w-96 flex-col rounded shadow-lg py-3 px-5">
              <h3 className="text-red-700 font-bold text-xl mb-2">Bandera</h3>
              <p className="text-justify">
                La Bandera de los Cuerpos de Bomberos será un pabellón de fondo rojo,
                por sus cuatro extremos el tricolor ecuatoriano, y en el centro contendrá el Escudo Oficial de la Institución.
                En su parte superior constará el nombre del lugar a que pertenece, y en la inferior el Lema "Abnegación y Disciplina".
              </p>
            </div>
          </div>
          <div className="text-center w-full px-8 mt-5 shadow-lg border rounded-lg pb-10">
            <h6 className="w-full font-bold px-8 text-red-700 font-bold text-xl mt-2">
              Himno de Cuerpo de Bomberos de MANTA
            </h6>
            <div className="flex w-full">
              <div className="px-8 h-full w-full rounded-md flex justify-center text-justify">
                <div className="flex flex-col items-center">
                  <h6 className="w-full font-bold px-8 text-center mb-2 text-red-700 mb-6">
                    Coro
                  </h6>
                  <div className="px-8 mb-4 flex justify-center text-justify">
                    Es acaso el bombero el soldado más altivo, más digno y
                    glorioso; el sin par defensor consagrado por su amor a un
                    deber luminoso.
                  </div>
                  <audio controls src="./audio/himnobomberomanta.mp3">
                    <a href="./audio/himnobomberomanta.mp3">
                      Himno del Bombero
                    </a>
                  </audio>
                </div>
                <div className="">
                  <h6 className="w-full font-bold px-8 text-center text-red-700">
                    Estrofas
                  </h6>
                  <div className="px-8 h-full flex justify-center text-center">
                    <div className="flex flex-col">
                      <div className="px-4">
                        <h6 className="w-full font-bold px-8 text-center">I</h6>
                        <div className="text-justify">
                          El deber es tu aliento fecundo; tu en héroe te has
                          convertido y hasta el mar prisionero y profundo su
                          potencia te da en su rugido.
                        </div>
                      </div>
                      <div className="px-4">
                        <h6 className="w-full font-bold px-8 text-center">
                          II
                        </h6>
                        <div className="text-justify">
                          Vuestro nombre, bombero de manta, gran honor tiene ya
                          conquistado y por eso mi numen te canta desde el sitio
                          do estás admirado.
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="px-4">
                        <h6 className="w-full font-bold px-8 text-center">
                          III
                        </h6>
                        <div className="text-justify">
                          Galardón a tu nombre, valiente!!... Gloria siempre a
                          tu causa, adalid. Lleva un lauro el bombero en la
                          frente, representa el bombero la lid.
                        </div>
                      </div>
                      <div className="px-4">
                        <h6 className="w-full font-bold px-8 text-center">
                          IV
                        </h6>
                        <div className="text-justify">
                          Qué te importa si el mal se encarniza o de llamas se
                          cubre el camino? Tu grandiosa misión simboliza un
                          ejemplo en el mundo, un destino.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Simbolos;
