import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CardNoticias = ({ id, firstImage, img, title, url, content }) => {
  const storage = "https://bp.bomberosmanta.gob.ec/storage/news";
  const rutaImg = storage + "/" + firstImage + "/" + img;
  return (
    <Link to={`/noticias/` + id}>
      <div className="bg-white shadow-lg border rounded h-full hover:scale-110 transform-gpu transition duration-700">
        <div className="h-full flex flex-col">
          <img
            className="rounded w-full max-h-full h-full"
            src={rutaImg}
            alt={title}
            style={{ objectFit: "cover", objectPosition: "50% 0%" }}
          />
          <div
            className="leading-6 text-white bg-black opacity-85 px-3 py-2 flex content-center justify-center items-center rounded-b"
            style={{
              minHeight: "25pt",
              height: "45pt",
              marginTop: "-45pt",
            }}
          >
            <span
              style={{
                WebkitLineClamp: "2",
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
              }}
              className="uppercase text-sm"
            >
              {title}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};
CardNoticias.prototype = {
  id: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  url: PropTypes.string,
  firstImage: PropTypes.string,
};

export default CardNoticias;
