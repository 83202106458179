import React from "react";
import Layout from "../layout/Layout";

const Terminos = () => {
    return (
        <Layout>
            <p>Estamos trabajando...!</p>
        </Layout>
    );
};

export default Terminos;
