import React from "react";
import CardValores from "../components/CardValores";
import Layout from "../layout/Layout";
import { valores } from "../utils/valores";

const Valores = () => {
  return (
    <Layout>
      <div className="">
        <h1 className="font-bold text-center text-4xl mt-4">
          Valores Institucionales
        </h1>

        <div className="mt-20 px-8" style={{ "paddingBottom": "140px" }}>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3">
            {valores?.map((valor, index) => {
              return (
                <div className="col-span-1" key={index}>
                  <CardValores
                    title={valor?.title}
                    content={valor?.content}
                    color={valor?.color}
                    ruta={valor?.imagen}
                    width={valor?.width}
                    key={index}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Valores;
